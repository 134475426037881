import { Lightbulb, TrendingUp, Zap } from 'lucide-react'

const features = [
  {
    icon: Lightbulb,
    title: 'Idea Extraction',
    description: 'Our AI analyzes podcasts to extract innovative business ideas and key insights.'
  },
  {
    icon: TrendingUp,
    title: 'Trend Analysis',
    description: 'Stay ahead of the curve with our trend identification and market opportunity assessment.'
  },
  {
    icon: Zap,
    title: 'Quick Implementation',
    description: 'Turn ideas into action with our step-by-step implementation guides and resources.'
  }
]

export default function Features() {
  return (
    <section className="py-20 px-4 bg-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-purple-700 mb-12">How Podthinker Works</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="text-center">
              <feature.icon className="w-12 h-12 text-purple-600 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

