import axios, { AxiosError } from 'axios';
import { Podcast, Idea } from '../types/index';
import { Episode as EpisodeType } from '../types/index';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: `${API_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  maxRedirects: 5
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for better error handling
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      config: error.config
    });

    // Handle 401 Unauthorized errors
    if (error.response?.status === 401) {
      // Clear the invalid token
      localStorage.removeItem('token');
      
      // Redirect to login page
      window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname);
    }

    return Promise.reject(error);
  }
);

export const login = async (email: string, password: string): Promise<{ access_token: string }> => {
  try {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    const response = await api.post('/auth/login', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    
    const { access_token } = response.data;
    localStorage.setItem('token', access_token);
    return response.data;
  } catch (error) {
    console.error('Failed to login:', error);
    throw error;
  }
};

export const getPodcasts = async (): Promise<Podcast[]> => {
  try {
    const response = await api.get('/podcasts/');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch podcasts:', error);
    throw error;
  }
};

export const getPodcast = async (id: string): Promise<Podcast> => {
  try {
    const response = await api.get(`/podcasts/${id}/`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch podcast ${id}:`, error);
    throw error;
  }
};

export const getEpisodes = async (id: string): Promise<EpisodeType[]> => {
  try {
    const response = await api.get(`/episodes/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIdeas = async (episodeId: string): Promise<Idea[]> => {
  try {
    const response = await api.get(`/ideas/${episodeId}/`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch ideas for episode ${episodeId}:`, error);
    throw error;
  }
};

export const updateIdeaVotes = async (ideaId: string, type: 'upvote' | 'downvote'): Promise<Idea> => {
  try {
    const response = await api.post(`/ideas/${ideaId}/vote/`, { type });
    return response.data;
  } catch (error) {
    console.error('Failed to update vote:', error);
    throw error;
  }
};

interface GetIdeasParams {
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  businessArea?: string;
  keyword?: string;
}

export const getAllIdeas = async (params: GetIdeasParams = {}) => {
  try {
    const queryParams = new URLSearchParams();
    
    if (params.page) queryParams.set('page', params.page.toString());
    if (params.sortBy) queryParams.set('sort_by', params.sortBy);
    if (params.sortOrder) queryParams.set('sort_order', params.sortOrder);
    if (params.businessArea) queryParams.set('business_area', params.businessArea);
    if (params.keyword) queryParams.set('keyword', params.keyword);

    const response = await api.get(`/ideas?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch ideas:', error);
    throw error;
  }
}; 