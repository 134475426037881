import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getPodcasts, login } from './services/api';
import { Podcast } from './types';
import { PodcastCard } from './components/podcast/PodcastCard';
import { LoginForm } from './components/auth/LoginForm';
import { PodcastPage } from './pages/PodcastPage';
import { ExploreIdeasPage } from './pages/ExploreIdeasPage';
import './styles/animations.css';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "./components/ui/carousel"
import { Card, CardContent } from "./components/ui/card"
import Features from "./components/ui/Features"

// // Protected Route wrapper component
// interface ProtectedRouteProps {
//   isAuthenticated: boolean;
//   children: React.ReactNode;
// }

// const ProtectedRoute = ({ isAuthenticated, children }: ProtectedRouteProps) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace />;
//   }
//   return <>{children}</>;
// };

function App() {
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is already authenticated
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    // if (!isAuthenticated) return;

    const fetchPodcasts = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getPodcasts();
        setPodcasts(data);
      } catch (err) {
        const errorMessage = err instanceof Error 
          ? err.message 
          : 'Failed to fetch podcasts. Please try again later.';
        setError(`Error: ${errorMessage}`);
        console.error('Error fetching podcasts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPodcasts();
  }, [isAuthenticated]);

  const handleLoginSuccess = async (email: string, password: string) => {
    try {
      const response = await login(email, password);
      const token = response.access_token;
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-600">
        {/* Navigation Bar - Now always visible */}
        <nav className="bg-transparent py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              {/* Logo and Brand */}
              <div className="flex items-center">
                <a href="/" className="flex items-center">
                  <span className="text-4xl font-archivo-black tracking-tight text-white font-black
                    before:content-['•'] before:mr-2 before:text-pink-300
                    after:content-['•'] after:ml-2 after:text-blue-300">
                    podthinker
                    <span className="inline-block w-[0.5em] text-transparent bg-clip-text bg-gradient-to-r from-pink-300 to-blue-300 animate-blink">_</span>
                  </span>
                </a>
              </div>

              {/* Navigation Links */}
              <div className="hidden md:flex items-center space-x-8">
                <a href="/ideas" className="text-white/80 hover:text-white px-3 py-2 text-sm font-medium transition-colors">
                  Everything
                </a>
                <a href="/about" className="text-white/80 hover:text-white px-3 py-2 text-sm font-medium transition-colors">
                  About
                </a>
                <a href="/team" className="text-white/80 hover:text-white px-3 py-2 text-sm font-medium transition-colors">
                  Team
                </a>
                {isAuthenticated ? (
                  <button
                    onClick={handleLogout}
                    className="text-white/80 hover:text-white px-3 py-2 text-sm font-medium transition-colors"
                  >
                    Logout
                  </button>
                ) : (
                  <a href="/login" className="text-white/80 hover:text-white px-3 py-2 text-sm font-medium transition-colors">
                    Login
                  </a>
                )}
              </div>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path="/login" element={
            isAuthenticated ? 
              <Navigate to="/ideas" replace /> : 
              <LoginForm onSuccess={handleLoginSuccess} />
          } />
          
          <Route path="/" element={
            <>
              {loading ? (
                <div className="min-h-screen flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
                </div>
              ) : error ? (
                <div className="min-h-screen flex items-center justify-center">
                  <div className="bg-white/20 backdrop-blur-sm p-6 rounded-lg max-w-md text-center">
                    <p className="text-white mb-4">{error}</p>
                    <button
                      onClick={() => window.location.reload()}
                      className="bg-white/20 hover:bg-white/30 text-white px-4 py-2 rounded transition-colors"
                    >
                      Retry
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {/* Hero Section */}
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32 text-center">
                    <div className="relative z-10">
                      <h1 className="text-6xl font-bold mb-6 tracking-tight">
                        <span className="text-white">Turn Podcast Ideas into </span>
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-300 to-blue-300">
                          Business Gold
                        </span>
                      </h1>
                      <p className="text-xl text-white/80 max-w-2xl mx-auto mb-12">
                        We make podcast idea extraction glamorous. Transform episodes into actionable business opportunities.
                      </p>
                      <a href="/ideas" className="relative group inline-block">
                        <div className="absolute -inset-1 bg-gradient-to-r from-pink-300 to-blue-300 rounded-lg blur-lg 
                          opacity-60 group-hover:opacity-100 transition-all duration-300"></div>
                        <div className="relative px-8 py-3 bg-black rounded-lg leading-none flex items-center">
                          <span className="text-lg font-semibold bg-gradient-to-r from-pink-300 to-blue-300 bg-clip-text text-transparent">
                            Explore Ideas
                          </span>
                          <span className="text-pink-300 group-hover:translate-x-1 transition-transform duration-300 ml-2">
                            →
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <Features />
                  {/* Replace Podcasts Grid with Carousel */}
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16 pt-16">
                    <h2 className="text-3xl font-bold mb-12 text-center text-white">Latest Podcasts</h2>
                    <Carousel className="w-full max-w-5xl mx-auto">
                      <CarouselContent>
                        {podcasts.map((podcast) => (
                          <CarouselItem key={podcast.id} className="md:basis-1/2 lg:basis-1/3">
                            <div className="p-1">
                              <Card className="bg-black/20 backdrop-blur-sm border-0">
                                {/* <CardContent className="flex aspect-square items-center justify-center p-6"> */}
                                  <PodcastCard podcast={podcast} isAuthenticated={isAuthenticated} />
                                {/* </CardContent> */}
                              </Card>
                            </div>
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      <CarouselPrevious className="text-white border-white/20 hover:bg-white/10" />
                      <CarouselNext className="text-white border-white/20 hover:bg-white/10" />
                    </Carousel>
                  </div>

                  {/* Decorative Elements */}
                  <div className="absolute top-40 left-10 w-20 h-20 bg-pink-500 rounded-full blur-3xl opacity-20"></div>
                  <div className="absolute top-60 right-10 w-32 h-32 bg-purple-500 rounded-full blur-3xl opacity-20"></div>
                  <div className="absolute bottom-40 left-1/4 w-24 h-24 bg-blue-500 rounded-full blur-3xl opacity-20"></div>
                </>
              )}
            </>
          } />
          
          <Route path="/podcasts/:id" element={
            // <ProtectedRoute isAuthenticated={isAuthenticated}>
              <PodcastPage />
            // </ProtectedRoute>
          } />
          
          <Route path="/ideas" element={
            // <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ExploreIdeasPage isAuthenticated={isAuthenticated} />
            // </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
