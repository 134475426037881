import { useState } from 'react';
import { Podcast, Episode } from '../../types/index';
import { EpisodeModal } from '../episode/EpisodeModal';
import { useNavigate } from 'react-router-dom';

interface PodcastCardProps {
  podcast: Podcast;
  isAuthenticated: boolean;
}

export const PodcastCard = ({ podcast }: PodcastCardProps) => {
  const navigate = useNavigate();

  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);

  const handleClick = () => {
    navigate(`/podcasts/${podcast.id}`);
  };




  return (
    <>
      <div className="bg-white/20 aspect-square backdrop-blur-sm rounded-lg shadow-md overflow-hidden hover:bg-white/30 transition-all">
        <div
          className="p-6 cursor-pointer"
          onClick={handleClick}
        >
          <h3 className="text-xl font-bold mb-2 text-white">{podcast.title}</h3>
          <p className="text-gray-200 mb-2">{podcast.description}</p>
          <div className="text-sm text-gray-300">
            <span className="mr-4">Channel: {podcast.channel_name}</span>
            <a
              href={podcast.channel_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300"
              onClick={(e) => e.stopPropagation()}
            >
              Visit Channel
            </a>
          </div>
        </div>
      </div>

      {selectedEpisode && (
        <EpisodeModal
          episode={selectedEpisode}
          onClose={() => setSelectedEpisode(null)}
        />
      )}
    </>
  );
}; 